import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import SiteNavbar from './SiteNavbar';
import Home from './Home';
import NotFound from "./NotFound";
import RandomJoke from "./RandomJoke";

const App = () => {

  return (
    <>
      <BrowserRouter>
        <SiteNavbar />
        <div className="app-content">
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/randomjoke">
              <RandomJoke />
            </Route>
            <Route>
              <NotFound />
            </Route>
          </Switch>
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;