import React from "react";

const Home = () => {
    return (
        <>
            <h3>Coming Soon™</h3>
            <img style={{ maxWidth: 250 }} alt="Coming Soon GIF" src="https://media3.giphy.com/media/H7SaUQ5vEbocJqYZKO/giphy.gif?cid=ecf05e47augr3wxzabmazmx9yhjd2450je3hpu103b2ayak1&rid=giphy.gif&ct=g"></img>
        </>
    );
}

export default Home;