import React, { useEffect, useState } from 'react';
import { Button, Card, Spinner } from 'react-bootstrap';

const DailyJoke = () => {

    const [joke, setJoke] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getJoke();
    }, []);

    const getJoke = async () => {
        setIsLoading(true);
        fetch('https://v2.jokeapi.dev/joke/Any?blacklistFlags=nsfw,religious,political,racist,sexist,explicit')
            .then(response => response.json())
            .then(data => {
                setJoke(data);
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <>
            {isLoading && <Spinner animation='border' role='status' />}
            {!isLoading && joke &&
                <>
                    <Card className="text-center">
                        <Card.Header className="text-muted">Category: {joke.category}</Card.Header>
                        <Card.Body>
                            <Card.Text>
                                {joke.setup && joke.delivery &&
                                    <>
                                        <p>{joke.setup}</p>
                                        <p className='fst-italic'>{joke.delivery}</p>
                                    </>
                                }
                                {joke.joke &&
                                    <>
                                        <p>{joke.joke}</p>
                                    </>
                                }
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            <Button variant="primary" onClick={getJoke}>Get Another Joke</Button>
                        </Card.Footer>
                    </Card>

                </>
            }
        </>
    );
}

export default DailyJoke;