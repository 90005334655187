import React from "react";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";

const SiteNavbar = () => {

    return (
        <Navbar bg="dark" variant="dark" sticky="top" expand="lg">
            <Container>
                <Navbar.Brand href="/">Kenneth Lewis</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="/">Home</Nav.Link>
                        <NavDropdown title="Projects" id="basic-nav-dropdown">
                            <NavDropdown.Item href="https://react-tutorial-final.netlify.app/" target='_blank'>SuperM</NavDropdown.Item>
                            <NavDropdown.Item href="https://react-weather-app-kl.netlify.app/" target='_blank'>Weather Search</NavDropdown.Item>
                            <NavDropdown.Item href="https://gcucapstone.vercel.app/" target='_blank'>Master's Capstone Project</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link href="https://www.linkedin.com/in/kennethmlewis/" target="_blank" active={false}>
                            LinkedIn
                        </Nav.Link>
                        <Nav.Link href="/randomjoke">Random Joke</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar >
    );
};

export default SiteNavbar;